import { ADVISOR_BOOKING_SERVICE_NAME } from 'core'

export const getServiceNameFromUrl = (url: string) => {
    switch (true) {
        case url.endsWith('/chat'):
        case url.endsWith('/chat/'):
            return 'Online Advice Team'
        default:
            return ADVISOR_BOOKING_SERVICE_NAME
    }
}
