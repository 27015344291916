export const storage = {
    get: (key: string) => {
        const value = sessionStorage.getItem(key)
        if (value) return JSON.parse(value)
        return null
    },
    set: (key: string, value: Object) => {
        sessionStorage.setItem(key, JSON.stringify(value))
    }
}
