import { getBusinessDays } from 'common/utils/getBusinessDays'

/**
 * Returns an array excluding the current day (first element of the array)
 *  if it is after 6pm
 *
 * @param {string[]} dates array of dates to be filtered
 * @param {number} currentHour current hour as a number to determine whether today should be included (after 6pm = no)
 */
export const datesWithOrWithoutToday = (dates: Date[], currentHour: number) => {
    const tooLateToday = currentHour > 18
    if (tooLateToday) {
        dates.shift()
    }
    return dates
}

/**
 * Advisor booking - specific version of getBusinessDays.
 * Returns an array containing all the business days, excluding
 * today if it is after 6pm
 *
 * @param {Date} startDate
 * @param {number} calendarDaysToAdd
 * @param {Date[]} bankHolidays
 * @param {number} currentHour
 */
export const getAdvisorBookingDays = (
    startDate: Date,
    calendarDaysToAdd: number,
    bankHolidays: Date[],
    currentHour: number
) =>
    datesWithOrWithoutToday(
        getBusinessDays({ startDate, calendarDaysToAdd, bankHolidays }),
        currentHour
    )
