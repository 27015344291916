import {
    // ADVISOR_BOOKING_POR_TEAM_NAME,
    ADVISOR_BOOKING_SERVICE_NAME,
    ADVISOR_BOOKING_TEAM_NAME,
    ADVISOR_BOOKING_URL,
} from 'core'
import { padStart } from 'lodash'
import { AdvisorBookingProps } from '../AdvisorBooking'
import {
    AdvisorDetails,
    AvailableAppointments,
    formatDOB,
    getAdvisorFromAvailableAppointments,
    getRequestHeaders,
    topicToNeedAreaMapper,
} from '../utils'
import { PreScreen } from '../utils/interface'

export interface SubmitBody {
    NeedArea: string
    ReviewType: string
    subject: string
    Source: string
    LeadSource: number
    LeadSubsource: number
    servicename: string
    TeamName: string
}

export const onSubmit = async (
    formData: any,
    availableAppointments: AvailableAppointments,
    url: string,
    preScreen?: PreScreen,
    customBody?: Partial<SubmitBody>,
    customCrmData?: AdvisorBookingProps['customCrmData']
): Promise<AdvisorDetails> => {
    const createReviewEndpoint = `${ADVISOR_BOOKING_URL}createreview`

    const endTimeHour = padStart(
        String(+formData.appointmentTime.value.slice(0, 2) + 1),
        2,
        '0'
    )
    const endTimeString = `${endTimeHour}:00:00`
    const startTime = `${formData.appointmentDate} ${formData.appointmentTime.value},000`
    const endTime = `${formData.appointmentDate} ${endTimeString},000`

    const applicantData = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        dateofbirth: formatDOB(new Date(formData.dateOfBirth)),
        MobilePhone: formData.phoneNumber,
        EmailAddress: formData.email,
    }

    const partnerData = formData.hasPartner
        ? {
              FirstName2ndLife: formData.partnerFirstName,
              Lastname2ndLife: formData.partnerLastName,
              Dateofbirth2ndLife: formatDOB(
                  new Date(formData.partnerDateOfBirth)
              ),
              MobilePhone2ndLife: formData.partnerPhoneNumber,
              EmailAddress2ndLife: formData.partnerEmail,
          }
        : {}

    const appointmentData = {
        NeedArea: topicToNeedAreaMapper(formData.topic),
        AdvisorId: getAdvisorFromAvailableAppointments(
            formData.appointmentTime.value,
            availableAppointments
        ),
        startTime,
        endTime,
    }

    const metaData = {
        ReviewType: 'Financial Review',
        subject: `[Booking service review] for ${applicantData.firstname} ${applicantData.lastname}`,
        Source: preScreen?.metamoVariant
            ? preScreen?.isAdvisorView
                ? 'Metamo Staff'
                : 'Metamo'
            : 'ILFS Web',
        LeadSource: getLeadSource(customCrmData, preScreen),
        CampaignId: customCrmData?.campaignId,
        LeadSubsource: getLeadSubsource(customCrmData, preScreen),
        servicename:
            // por ? ADVISOR_BOOKING_POR_TEAM_NAME :
            ADVISOR_BOOKING_SERVICE_NAME,
        SourceBranchName: preScreen?.sourceBranchName, // unique branch name for credit union branch
        TeamName: ADVISOR_BOOKING_TEAM_NAME,
    }

    const createReviewBody = {
        ...applicantData,
        ...partnerData,
        ...appointmentData,
        ...metaData,
        ...customBody,
    }

    const data = await fetch(createReviewEndpoint, {
        headers: {
            ...(await getRequestHeaders()).headers,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(createReviewBody),
    })

    if (!data.ok) {
        throw new Error('Error creating review')
    }

    const advisorDetails: {
        EmailAddress: string
        Fullname: string
        PhoneNumber: string
    } = await data.json()

    return {
        advisorName: advisorDetails.Fullname,
        advisorEmail: advisorDetails.EmailAddress,
        advisorPhone: advisorDetails.PhoneNumber,
    }
}

const getLeadSource = (customCrmData: any, preScreen: any) => {
    if (customCrmData?.sourceId) {
        return Number(customCrmData.sourceId) //custom data
    } else if (preScreen?.metamoVariant) {
        return 857190002 //metamo
    } else {
        return 857190005
    }
}
const getLeadSubsource = (customCrmData: any, preScreen: any) => {
    if (customCrmData?.subsourceId) {
        return Number(customCrmData.subsourceId) //custom data
    }
    if (preScreen?.metamoVariant && preScreen?.isAdvisorView) {
        return 857190039 //metamo staff
    }
    if (preScreen?.metamoVariant) {
        return 857190014 //metamo customer
    }
    return 857190045
}
