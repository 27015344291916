import {
    ADVISOR_BOOKING_SERVICE_NAME,
    ADVISOR_BOOKING_TEAM_NAME,
    ADVISOR_BOOKING_URL,
} from 'core/config/env'
import { format } from 'date-fns'
import {
    AvailableAppointments,
    getRequestHeaders,
    getTimeslotsFromAvailableAppointments,
} from '../utils'
export interface AvailableTimeslotsBody {
    teamName: string
    servicename: string
    startTime: string
    endTime: string
}

export const getAvailableTimeslots = async (
    date: Date,
    customBody?: Partial<AvailableTimeslotsBody>
) => {
    const availableTimesEndpoint = `${ADVISOR_BOOKING_URL}retrieveAvailableAppointments`

    const convertedDate = format(date, 'dd-MM-yyyy')

    const availableTimesBody: AvailableTimeslotsBody = {
        teamName: ADVISOR_BOOKING_TEAM_NAME,
        servicename: ADVISOR_BOOKING_SERVICE_NAME,
        startTime: `${convertedDate} 07:00:00,000`,
        endTime: `${convertedDate} 23:59:00,000`,
        ...customBody,
    }

    const data = await fetch(availableTimesEndpoint, {
        headers: {
            ...(await getRequestHeaders()).headers,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(availableTimesBody),
    })

    const availableAppointments: AvailableAppointments = await data.json()

    return {
        timeslots: getTimeslotsFromAvailableAppointments(availableAppointments),
        availableAppointments,
    }
}
