import axios from 'axios'
import { storage } from 'common/utils/storage'

export const getToken = async (url: string, tokenName: string) => {
    const oldToken = tokenStorage.get(tokenName)
    if (oldToken && !isTokenExpired(oldToken?.expiresOn)) return oldToken

    const token = await retrieveToken(url)
    tokenStorage.set(token, tokenName)
    return token
}

const retrieveToken = async (url: string) => {
    const { data: token } = await axios.get(url)
    return token
}

const tokenStorage = {
    get: (tokenName: string) => storage.get(`token-${tokenName}`),
    set: (token: Object, tokenName: string) =>
        storage.set(`token-${tokenName}`, token)
}

const isTokenExpired = (expiryDate: string) => {
    return new Date(expiryDate) <= new Date()
}
