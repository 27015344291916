// #region setup
export interface SubjectTag {
    label: string
    path: string
    matchType?: 'exact' | 'startsWith' | 'contains'
    topics: string[]
    defaultTopic: string
}

export interface SubjectTags {
    [key: string]: SubjectTag
}

const defaultTopics = [
    'Life Insurance',
    'Workplace Pension',
    'Personal Pension',
    'Savings',
    'Investments',
    'Illness Benefits',
    'Retirement Options'
]

export const defaultTopicsConfig = {
    topics: defaultTopics,
    defaultTopic: defaultTopics[0]
}

export const pensionsTopicsConfig = {
    topics: defaultTopics,
    defaultTopic: 'Personal Pension'
}

export const investmentsTopicsConfig = {
    topics: defaultTopics,
    defaultTopic: 'Investments'
}

const defaultLabel = `Booking service review`

const topicsWithWellbeing = ['Financial Wellbeing', ...defaultTopics]

const topicsWithWellbeingConfig = {
    topics: topicsWithWellbeing,
    defaultTopic: topicsWithWellbeing[0]
}

export const topicsPorConfig = {
    ...defaultTopicsConfig,
    defaultTopic: 'Retirement Options'
}

// #endregion setup

export const subjectTags: SubjectTags = {
    webinar: {
        label: `Webinar`,
        path: '/webinar',
        ...topicsWithWellbeingConfig
    },
    por: {
        label: `POR campaign`,
        path: '/get-good-news-at-50',
        ...topicsPorConfig
    },
    healthCrossSell: {
        label: `healthCrossSell`,
        path: '/health-financial-advice',
        ...topicsWithWellbeingConfig
    },
    myLifeCrossSell: {
        label: `myLifeCrossSell`,
        path: '/mylife-financial-advice',
        ...topicsWithWellbeingConfig
    },
    myLifeBlogCrossSell: {
        label: `myLifeBlogCrossSell`,
        path: '/blog/steps-towards-getting-financially-fit',
        ...topicsWithWellbeingConfig
    },
    responsiblePensions: {
        label: `irishlife.ie/responsible-pensions`,
        matchType: 'exact',
        path: '/pensions/responsible-pensions',
        ...pensionsTopicsConfig
    },
    pensionsLanding: {
        label: `irishlife.ie/pensions`,
        matchType: 'exact',
        path: '/pensions',
        ...pensionsTopicsConfig
    },
    pensions: {
        label: defaultLabel,
        path: '/pensions',
        matchType: 'startsWith',
        ...pensionsTopicsConfig
    },
    investments: {
        label: defaultLabel,
        matchType: 'startsWith',
        path: '/investments',
        ...investmentsTopicsConfig
    },
    getAdvice: {
        label: `irishlife.ie/get-advice`,
        path: '/get-advice',
        ...defaultTopicsConfig
    }
}
