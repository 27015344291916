import { Box, BoxProps } from '@chakra-ui/react'

import { BookAppointment } from '@irishlifedigitalhub/ds.organisms.book-appointment'
import { useLocation } from '@reach/router'
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import React from 'react'
import { getAvailableDatesMock } from './config/getAvailableDates'
import { getAvailableTimeslots } from './config/getAvailableTimeslots'
import { onSubmit } from './config/onSubmit'
import { getServiceNameFromUrl } from './utils/getServiceNameFromUrl'
import { getSubjectFromPath, getTagFromPath } from './utils/getSubjectFromPath'
import { getTeamFromUrl } from './utils/getTeamFromUrl'
import { PreScreen } from './utils/interface'

export interface AdvisorBookingProps {
    sx?: BoxProps['sx']
    slim?: boolean
    preScreen?: PreScreen
    customCrmData?: {
        campaignId?: string
        sourceId?: string
        subsourceId?: string
    }
    customContent?: {
        title?: string
        body?: string
        bulletpoints?: string[]
        advisor_image?: string
        advisor_jobtitle?: string
        advisor_name?: string
        advisor_quotation?: string
        custom_campaign_id?: string
        custom_source_id?: string
        custom_subsource_id?: string
    }
}

export const AdvisorBooking: React.FC<AdvisorBookingProps> = (props) => {
    const { sx, slim, preScreen, customCrmData, customContent } = props

    const { pathname } = useLocation()
    const tag = getTagFromPath(pathname)

    return (
        <Box
            data-testid='AdvisorBooking'
            sx={{
                bg: slim ? '#fff' : undefined,
                px: slim ? 59 : undefined,
                mb: slim ? undefined : '-133px',
                ...sx,
            }}
        >
            <BookAppointment
                customContent={customContent}
                defaultTopic={tag.defaultTopic}
                onInteracted={() => {
                    fireAnalyticsEvent({
                        name: 'IL_book_appointment_component',
                        properties: {
                            step: 'interacted',
                        },
                    })
                }}
                onInteractedWithCurrentCustomersCallButton={() => {
                    fireAnalyticsEvent({
                        name: 'clickToCall',
                        properties: {
                            clickText: '017041010',
                        },
                    })
                }}
                onInteractedWithNeedAdviceCallButton={() => {
                    fireAnalyticsEvent({
                        name: 'clickToCall',
                        properties: {
                            clickText: '017041979',
                        },
                    })
                }}
                onInteractedWithBookAppointmentButton={() => {
                    fireAnalyticsEvent({
                        name: 'IL_book_appointment_component',
                        properties: {
                            step: 'form_opened',
                        },
                    })
                }}
                onCompleted={() => {
                    fireAnalyticsEvent({
                        name: 'IL_book_appointment_component',
                        properties: {
                            step: 'completed',
                        },
                    })
                }}
                onNextStep={(step, topic) => {
                    fireAnalyticsEvent({
                        name: 'IL_book_appointment_component',
                        properties: {
                            step: (step + 1).toString(),
                            status: `${step + 1}/3`,
                            topic,
                        },
                    })
                }}
                getAvailableDates={getAvailableDatesMock}
                getAvailableTimeslots={async (date) => {
                    try {
                        const { timeslots, availableAppointments } =
                            await getAvailableTimeslots(date, {
                                teamName: getTeamFromUrl(pathname),
                            })
                        return { timeslots, availableAppointments }
                    } catch (e) {
                        return { timeslots: [], availableAppointments: [] }
                    }
                }}
                onSubmit={(formData, availableAppointments) => {
                    return onSubmit(
                        formData,
                        availableAppointments,
                        pathname,
                        preScreen,
                        {
                            subject: getSubjectFromPath(pathname, formData),
                            TeamName: getTeamFromUrl(pathname),
                            servicename: getServiceNameFromUrl(pathname),
                        },
                        customCrmData
                    )
                }}
                slim={slim}
                topics={tag.topics}
                preScreen={preScreen}
            />
        </Box>
    )
}

const AdvisorBookingWrapper: React.FC<any> = (props) => {
    return <AdvisorBooking {...props} />
}
export default AdvisorBookingWrapper
