import axios from 'axios'
import {
    ADVISOR_BOOKING_SERVICE_NAME,
    ADVISOR_BOOKING_TEAM_NAME,
    ADVISOR_BOOKING_URL,
} from 'core/config/env'

import {
    AvailableDates,
    convertFromApiDateString,
    getRequestHeaders,
} from '../utils'
import { getAdvisorBookingDays } from '../utils/getAdvisorBookingDays'

export const getAvailableDatesMock = async () => {
    const bankHolidays = [
        '30/10/2023',
        '25/12/2023',
        '26/12/2023',
        '01/01/2024',
        '05/02/2024',
        '01/04/2024',
    ]

    const availableDates: AvailableDates = getAdvisorBookingDays(
        new Date(),
        30,
        bankHolidays.map(convertFromApiDateString),
        new Date().getHours()
    )

    const fridayBeforeIndex = availableDates.findIndex(
        (date) => date.getDate() === 2
    )

    const satPassed = new Date().getDate() > 3 && new Date().getMonth() === 10

    const availableDatesWithNextSaturday = [
        ...availableDates.slice(0, fridayBeforeIndex + 1),
        new Date('Sat Oct 07 2023 00:00:00 GMT+0100 (Greenwich Mean Time)'),
        new Date('Sat Oct 14 2023 00:00:00 GMT+0100 (Greenwich Mean Time)'),
        ...availableDates.slice(fridayBeforeIndex + 1),
    ]

    return satPassed ? availableDates : availableDatesWithNextSaturday
}

export const getAvailableDates = async () => {
    const availableDatesEndpoint = `${ADVISOR_BOOKING_URL}retrieveAvailableAppointmentDays`

    const availableDatesBody = {
        servicename: ADVISOR_BOOKING_SERVICE_NAME,
        teamName: ADVISOR_BOOKING_TEAM_NAME,
    }

    const { data } = await axios.post<AvailableDates>(
        availableDatesEndpoint,
        availableDatesBody,
        await getRequestHeaders()
    )

    return data.map((date) => new Date(date))
}
