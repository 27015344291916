import _ from 'lodash'
import { defaultTopicsConfig, subjectTags } from '../config/subjectTags'

export const getSubjectFromPath = (path: string, formData: any) => {
    return getSubjectString(getTagFromPath(path).label, formData)
}

export const getTagFromPath = (path: string) => {
    return (
        _.find(subjectTags, tag => {
            const searchFunction =
                tag.matchType === 'startsWith'
                    ? _.startsWith
                    : tag.matchType === 'contains'
                    ? _.includes
                    : _.isEqual
            return (
                searchFunction(path, tag.path) ||
                searchFunction(path, tag.path + '/')
            )
        }) || {
            label: `Booking service review`,
            path: '/',
            ...defaultTopicsConfig
        }
    )
}

const getSubjectString = (tag: string, formData: any) =>
    `[${tag}] ${getSubjectPostfix(formData)}`

const getSubjectPostfix = (formData: any) =>
    `for ${formData.firstName} ${formData.lastName}`
