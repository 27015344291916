import moment from 'moment'

/**
 * Query parameters for getBusinessDays query
 *
 * @interface BusinessDaysQuery
 */
interface BusinessDaysQuery {
    startDate: Date
    calendarDaysToAdd: number
    bankHolidays?: Date[]
}

/**
 * Returns an array of Date objects. All the weekdays (optionally excl. bank holidays
 * & incl. working weekends). expects a number representing the current hour.
 * After 5pm no appointments for the same day are possible.
 *
 * @param {Date} startDate starting point of the range
 * @param {number} calendarDaysToAdd number of calendar days to add to the range
 * @param {Date[]} [bankHolidays=[]] array of bank holiday dates
 * @return {*}  {Date[]} array of business days' Date objects
 */
export const getBusinessDays: (
    businessDaysQuery: BusinessDaysQuery
) => Date[] = ({ startDate, calendarDaysToAdd, bankHolidays = [] }): Date[] => {
    const dates = getWorkingDateArray(
        getDateArray(
            startDate,
            moment(startDate)
                .add(calendarDaysToAdd, 'days')
                .toDate()
        ),
        prepareDateArray(bankHolidays),
        []
    )

    return dates.map(date => moment(date, 'ddd MMM DD YYYY').toDate())
}

/**
 * Returns an array containing all the
 * dates between start date and an end date. Expects two `Date`
 * objects as parameters. The dates are inclusive.
 *
 * @param {Date} start
 * @param {Date} end
 * @return {*}
 */
const getDateArray = function(start: Date, end: Date) {
    const result: string[] = []
    while (start <= end) {
        result.push(start.toString().substring(0, 15)) //save only the Day MMM DD YYYY part
        start.setDate(start.getDate() + 1)
    }
    return result
}

/**
 * Converts an array of dates to an array of strings
 * in the 'Day MMM DD YYYY' format
 *
 * @param {Date[]} datesArray
 * @return {*}
 */
const prepareDateArray = (datesArray: Date[]) =>
    datesArray.map(date => new Date(date).toString().substring(0, 15))

/**
 * Returns an array containing all the business days between start
 * date and an end date. Expects three parameters: an array of dateqs
 * to exculde bank holidays & working weekends' dates from, an array
 * of bank holidays and an array of working weekends' dates.
 *
 * @param {string[]} dates
 * @param {string[]} holidaysArray
 * @param {string[]} workingWeekendDates
 * @return {*}
 */
const getWorkingDateArray = function(
    dates: string[],
    holidaysArray: string[],
    workingWeekendDates: string[]
) {
    // remove holidays
    const arr = dates.filter(function(dt) {
        return holidaysArray.indexOf(dt) < 0
    })

    // remove weekend dates that are not working dates
    return arr.filter(function(dt) {
        if (dt.indexOf('Sat') > -1 || dt.indexOf('Sun') > -1) {
            if (workingWeekendDates.indexOf(dt) > -1) {
                return dt
            } else {
                return false
            }
        } else {
            return dt
        }
    })
}
