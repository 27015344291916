import { getToken } from 'common/utils/getToken'
import {
    ADVISOR_BOOKING_API_KEY,
    ADVISOR_BOOKING_SUBSCRIPTION_KEY,
    ADVISOR_BOOKING_TOKEN_URL
} from 'core'
import { format, parse } from 'date-fns'
import _ from 'lodash'

export const topicToNeedAreaMapper = (topic: string) => {
    const TOPIC_TO_NEEDAREA_MAP: { [key: string]: string } = {
        'Financial Advice': 'Financial Advice',
        'Life Insurance': 'Protection',
        'Workplace Pension': 'Financial Advice',
        'Personal Pension': 'Pension',
        Savings: 'Savings',
        Investments: 'Investments',
        'Illness Benefits': 'Protection',
        'Retirement Options': 'Post Retirement'
    }

    return TOPIC_TO_NEEDAREA_MAP[topic] || 'Financial Advice'
}

export const reviewTypeMapper = (topic: string) => {
    if (topic === 'Retirement Options') return 'Point of Retirement'
    return 'Financial Review'
}

export type AvailableDates = Date[]

export type AppointmentProposal = [string, string]

export type AvailableAppointment = {
    UserId: string
    Fullname: string
    EmailAddress: string
    AppointmentDate?: string
    MobilePhone?: string | null
    AppointmentProposal: AppointmentProposal[]
    NumberOfAvailableSlots: number
}

export type AvailableAppointments = AvailableAppointment[]

export interface AdvisorDetails {
    advisorName: string
    advisorPhone: string
    advisorEmail: string
}

export const getRequestHeaders = async () => {
    const tokenName = 'IL-AdvisorBooking'
    return {
        headers: {
            Authorization: `Bearer ${
                (await getToken(ADVISOR_BOOKING_TOKEN_URL, tokenName)).token
            }`,
            'Ocp-Apim-Subscription-Key': ADVISOR_BOOKING_SUBSCRIPTION_KEY,
            API_KEY: ADVISOR_BOOKING_API_KEY
        }
    }
}

export const convertDateToUsFormat = (date: string) => {
    return `${date.slice(3, 5)}/${date.slice(0, 2)}/${date.slice(6)}`
}
export const convertDateFromUsFormat = (date: string) => {
    return `${date.slice(3, 5)}/${date.slice(0, 2)}/${date.slice(6)}`
}

export const formatDOB = (date: Date): string =>
    `${format(new Date(date), 'dd-MM-yyyy')} 00:00:00,000`

export const getTimeslotsFromAvailableAppointments = (
    availableAppointments: AvailableAppointments
): AppointmentProposal[] => {
    let timeslots: AppointmentProposal[] = []
    availableAppointments.forEach(availableAppointment =>
        availableAppointment.AppointmentProposal.forEach(
            appointmentProposal => {
                timeslots.push([...appointmentProposal])
            }
        )
    )
    return _.uniqWith(timeslots, _.isEqual)
}

export const getAdvisorFromAvailableAppointments = (
    timeslot: string,
    availableAppointments: AvailableAppointments
) => {
    for (let availableAppointment of availableAppointments) {
        if (
            isTimeslotInAppointmentProposal(
                timeslot,
                availableAppointment.AppointmentProposal
            )
        ) {
            return availableAppointment.UserId
        }
    }
    return null
}

const isTimeslotInAppointmentProposal = (
    timeslot: string,
    appointmentProposal: AppointmentProposal[]
) => {
    for (let proposal of appointmentProposal) {
        if (timeslot === proposal[0]) {
            return true
        }
    }
    return false
}

export const apiDateFormat = 'dd/MM/yyyy'

export const convertFromApiDateString = (date: string) =>
    parse(date, apiDateFormat, new Date())
